<template>
  <div class="about">
    <img
      v-if="imgUrl"
      :src="imgUrl"
      class="img"
      style="height: auto"
      alt="456"
    />
    <div class="test" @click="test">6666</div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.drawAndShareImage();
  },
  data() {
    return {
      imgUrl: ""
    };
  },
  methods: {
    test() {
      // let img = document.querySelector(".img");
      // console.log(img);
      // let c = window.getComputedStyle(img);
      // console.log(c.height);
      // this.drawAndShareImage();
      console.log(this.imgUrl);
    },
    base64toFile(urlString, fileName) {
      const dataArr = urlString.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/png",
        endings: "native"
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".png", options); //返回文件流
    },
    drawAndShareImage() {
      let that = this;
      let canvas = document.createElement("canvas");
      canvas.width = 750;
      canvas.height = 1624;
      let context = canvas.getContext("2d");
      console.log(canvas.width, canvas.height);
      context.rect(0, 0, canvas.width, canvas.height);
      let bgImg = new Image();
      // bgImg.src = "./1.jpg"; // 背景图的url
      // let a = require("./1.jpg");
      bgImg.src = "https://mapi.yicheng120.com/img/1.jpg";
      bgImg.crossOrigin = "Anonymous";
      bgImg.onerror = (err) => {
        console.log(err);
      };
      // console.log(111);
      bgImg.onload = () => {
        context.drawImage(bgImg, 0, 0, 750, 1624);
        // let base64 = canvas.toDataURL("image/png");
        let img = new Image();
        // img.src = "./132154.png"; // 需要合进去的图片url
        // img.src = require("./132154.png"); // 需要合进去的图片url
        img.src = "https://mapi.yicheng120.com/img/2.png"; // 需要合进去的图片url
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          context.drawImage(img, 312, 1382, 126, 126);
          let base64 = canvas.toDataURL("image/png");
          // console.log(base64); // 这个就是合成后的图片链接，如果需要上传请查看我另外的文章
          let blob = this.base64toFile(base64, "1");
          const imageUrl = URL.createObjectURL(blob);

          console.log(imageUrl);

          that.imgUrl = base64;

          // console.log(imageUrl);
        };
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.about {
  // height: 16.24rem;
  // // overflow-y: auto;
  // position: relative;
  // // background-color: red;
  .test {
    width: 1.26rem;
    height: 1.26rem;
    background-color: red;
    // position: absolute;
    // // bottom: calc(1.16rem + 692px - 568px);
    // bottom: 1.16rem;
    // left: 50%;
    // transform: translateX(-50%);
  }
  .img {
    width: 100%;
    display: block;
    height: auto;
  }
}
</style>
